import styled from 'styled-components'
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const SupportingYourParentsWrapper = styled.div`
  &.supportparentswrapper {
		.video-container {
			&:nth-child(6) {
				.video-thumbnail-section-main {
					.community-video-btn {
						@media ${device.desktopsignup} {
							margin-top: -54px;
						}
            @media ${device.miniTopDeskMax}{
              margin-top: -55px;
            }
					}
				}
			}
		}
		.supporting-your-patients-clinical-trail {
			@media ${device.desktopStart} and ${device.largeMaxDesktop} {
				padding: 0 1rem;
			}
		}
		#be-inspired {
			@media ${device.desktopsignup} {
				margin-top: 12rem;
				margin-bottom: 4rem;
				font-family: 'Gotham', Arial, Helvetica, sans-serif;
  			font-size: 42px;
				line-height: 1.19;
				text-align: center;
			}
      @media ${device.laptopMedium} {
				font-family: 'Gotham', Arial, Helvetica, sans-serif;
  			font-size: 2.5rem;
				line-height: 3rem;
				text-align: center;
        padding: 30px 27px 0px;
			}
      @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax}{
        font-size: 4.2rem;
        font-weight: 700;
        line-height: 5rem;
        color: #000000;
        text-align: center;
        padding-top: 80px;
        padding-bottom: 56px;
        margin-bottom: 0;
      }
		}
		.clinical-trials-banner {
      height: auto;
			.internal-banner {
				.internal-banner__contant {
					.o-container {
						@media ${device.laptopPros} {
							padding: 0 5rem !important;
						}
					}
				}

				.internal-banner__image {
					img {
						@media ${device.laptopL} {
							margin-left: 70px;
						}
					}

					@media ${device.laptopL} {
						background-color: #000000;
						overflow: hidden;
					}
				}
			}
		}
    p {
      font-size: 1.8rem;
      line-height: 2.6rem;
      margin-bottom: 44px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      font-family: Gotham-Book, Arial, Helvetica, sans-serif;

			@media ${device.laptopMedium} {
				margin-bottom: 0;
			}

      @media ${device.desktopsignup} {
        font-size: 2.2rem;
        line-height: 1.5;
      }
    }

    .logo-section-edu {
      text-align: center;
      margin-bottom: 0px;
      padding-top: 24px;
      font-weight: 700;

      @media ${device.ipadLandscapemin} {
        font-size: 2.7rem;
        line-height: 1.41;
      }

      span {
        color: rgb(128, 0, 190);
      }

    }

    .resource-head {
      font-size: 1.8rem;
      line-height: 2.6rem;
      margin-bottom: 44px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;

      @media ${device.laptopMedium} {
        margin-bottom: 0;
      }
    }

		.resource-anchor-links-block {
			margin-top: 120px;
			padding-top: 24px;

      @media ${device.laptopPros} {
        margin-top: 60px;
      }
      @media ${device.laptopMedium} {
        margin-top: 90px;
      }

			p, h2 {
				font-size: 2.7rem;
				line-height: 3.8rem;
				width: 76%;
				font-weight: 700;
				text-align: center;

				&.resource-head {
					@media ${device.desktopsignup} {
						font-size: 4.2rem;
						line-height: 1.19;
						width: 100%;
						font-family: 'Gotham', Arial, Helvetica, sans-serif;
						margin-bottom: 4.8rem;
					}
				}

			}
			.resource-head {
				@media ${device.laptopMedium} {
					width: 100%;
					font-size: 2rem;
					line-height: 2.6rem;
					margin-bottom: 30px;
				}
			}

			.resource-links {
				width: auto;
				margin: auto;
    		display: flex;
			justify-content: space-around;
				@media ${device.laptopMedium} {
					display: none;
				}
				@media ${device.desktopsignup} and ${device.largeMaxDesktop} {
					max-width: 1068px;
				}

				a {
					background: #f7f7f7;
					padding-top: 10px;
					padding-bottom: 10px;
					padding-left: 45px;
					padding-right: 30px;
					text-decoration: none;
					font-weight: 500;
					text-align: center;
					justify-content: space-between;
					color: #8000BE;
					font-size: 2rem;
					max-width: 480px;
					height: fit-content;
					line-height: 3.8rem;

					&:nth-child(1) {
						margin-right: 3rem;
					}
					&:nth-child(2) {
						margin-right: 3rem;
					}

					@media ${device.desktopsignup} {
						font-size: 22px;
						line-height: 1.45;
						padding: 16px 28.7px 15px 28.7px;
					}

					@media ${device.ipadLandscapemin} {
						box-shadow: rgb(0 0 0 / 30%) 0px 5px 15px 0px;
					}

					&:after {
						content: '';
						width: 30px;
						height: 26px;
						display: inline-block;
						margin-left: 10px;
						position: relative;
						top: 2px;
    				vertical-align: middle;
					background-size: 12px;
					}

					@media ${device.laptopair2max} {
						padding: 10px 10px;
						font-size: 1.4rem;
						min-width: 290px;
    				text-align: center;
					}
					@media ${device.laptopMedium} {
						padding: 10px 10px;
					}
					@media ${device.laptopPros} {
						padding: 10px 10px;
						font-size: 1rem;
					}
				}
			}

		}

    .o-container {
      max-width: 1188px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 3rem;

			@media ${device.midDesktopMax} {
				padding: 0 5rem;
			}

			@media ${device.laptopPros} {
				padding: 0 3rem;
			}
			&.video-container {
        padding: 0px 2.7rem;

        @media ${device.tablet} {
          padding: 0px 2rem;
        }
        @media ${device.ipadLandscapemin} {
          padding: 0px 2rem;
        }

			}
			&.resource-anchor-links-block {
				@media ${device.desktopsignup} {
					padding: 0 1rem;
				}
			}
    }

		.video-container {
			.o-inner {
				.video-thumbnail-section-main {

					@media ${device.laptopPros} {
						margin-bottom: 0;
					}

					.video-thumbnail-right {
						.o-header--h3 {
							h3 {
								@media ${device.laptopMedium} {
									font-weight: 700;
								}
							}

							@media ${device.laptopPros} {
								max-width: 98%;
							}
						}
					}
				}
			}
		}

		.supporting-your-patients-clinical-trail {
			@media ${device.laptopPros} {
				margin-top: 48px;
			}
		}

    .bg-color-component {
      margin-top: 50px;
      margin-bottom: 120px;

			@media ${device.laptopMedium} {
				margin-top: 0;
    		margin-bottom: 48px;
			}
      @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax}{
        margin-bottom: 80px;
      }
    }

    .c-family--story {
      .c-family--story__inner {
        background-color: #f7f7f7;
        box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
        position: relative;
        padding: 10rem 9rem;
        color: #000000;

        &:before {
          content: "";
          background: linear-gradient(
            90deg,
            #8000be 0%,
            #594dc6 35%,
            #17d0d4 64%,
            #00ffd9 100%
          );
          width: 100%;
          height: 10px;
          position: absolute;
          top: 0;
          left: 0;
          height: 16px;
        }

        h2 {
          margin: 0 0 40px;
        }

        .o-header--h2 {
          font-size: 4.2rem;
          font-weight: 600;
          line-height: 5rem;
          color: #000000;
          font-family: 'Gotham', Arial, Helvetica, sans-serif;
        }
        .o-paragraph {
          font-weight: 500;
        }
      }
    }

    .o-text-book {
      font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
    }

    .o-text-center {
      text-align: center;
    }

    .supporting-patients-video-section {
      .thumbnail-video-section {
        @media ${device.tablet} {
          padding: 0;
        }

				h2 {
					&.o-internal--h2 {
						font-size: 4.2rem;
						font-weight: 700;
						line-height: 5rem;
						color: #000000;
						text-align: center;
						padding-bottom: 40px;

						@media ${device.laptopMedium} {
							font-size: 2.5rem;
							line-height: 3rem;
							padding-bottom: 24px;
						}
					}
				}

				h3 {
					&.o-internal--h2 {
						display: none;
					}

					~p {
						display: none;
					}
				}

				.video-container {
					@media ${device.laptopPros} {
						padding: 0;
					}

					@media ${device.tablet} {
						padding: 0;
					}

					.o-inner {
						padding: 0;

						.video-thumbnail-section-main {
							@media ${device.laptopMedium} {
								align-items: unset;
							}

							.o-button {
								@media ${device.laptopMedium} {
									order: 2 !important;
								}

								&:hover {
									background: #fff;
									border-color: #fff;
									font-weight: 700;
								}

								&:focus-visible {
									border: 2px solid white !important;
									box-shadow: inset 0 0 0 1px #fff !important;
								}
							}

							.video-thumbnail-left {

								.mx-auto {
									width: 100% !important;
									max-width: 100% !important;
								}

								@media ${device.laptopMedium} {
									order: 1;
								}
							}

							.video-thumbnail-right {
								padding-left: 2rem !important;

								@media ${device.laptopMedium} {
									order: 3;
									margin-top: 20px;
								}

								.o-common-title {
									p {
										@media ${device.laptopMedium} {
											line-height: 2.6rem !important;
											width: 100%;
										}

										strong {
											font-weight: 700;
										}
									}

									h3 {
										@media ${device.laptopMedium} {
											font-weight: 700;
										}
									}
								}

								.o-paragraph {
									p {
										color: #000000;
										width: 80%;
										padding-top: 27px;
										font-size: 1.8rem;
    								line-height: 2.6rem;
										font-weight: 500;
										font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
										padding: 0;

										@media ${device.laptopMedium} {
											width: 100%;
											font-size: 1.8rem !important;
    									line-height: 2.6rem !important;
										}
									}
								}
							}

							.community-video-btn {
								transition: all 0.3s ease;

								@media ${device.laptopMedium} {
									order: 2;
								}

								.dtp-arrow-down {
									&:before {
										@media ${device.laptopMedium} {
											top: 4px;
											position: relative;
										}
									}

									&.rotate {
										&:before {
											position: relative;
											top: -5px;

											@media ${device.laptopMedium} {
												top: 2px;
											}
										}
									}
								}
							}

							.video-transcript-copy {
								background: #fff;
								box-shadow: none;
								height: 185px;

								@media ${device.laptopMedium} {
									order: 3;
									margin-top: 0;
									padding: 20px 20px 20px 0;
									margin-top: 15px;
									position: absolute;
									top: 55%;
								}

								p {
									margin: 0;
								}

							}

							.video-transcript-copy--genes {
								@media ${device.laptopMedium} {
									margin-top: 15px;
									position: absolute;
									top: 55%;
								}
							}

							&:last-child {
								padding: 0;
							}
						}
					}
				}
			}
		}

    .clinical-trials-banner {
      .internal-banner {
				margin-bottom: 32px;

        &:after {
          top: calc(100% - 5px);

					@media ${device.laptopMedium} {
						height: 32px;
					}
        }

        .internal-banner__contant {

          @media ${device.ipadLandscapemin} {
            top: 50%;
            transform: translateY(-50%);
          }


					h1 {
            &.o-header--h1 {
              @media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
                max-width: 760px !important;
              }
            }
					}

          .o-container {
            max-width: 1188px;
            margin-left: auto;
            margin-right: auto;
            padding: 0 2rem;

						@media ${device.midDesktopMax} {
							padding: 0 5rem;
						}

						@media ${device.laptopMedium} {
							max-width: 718px;
							font-size: 2rem;
							line-height: 2.6rem;
						}

            .internal-banner__large-title {
              font-size: 5.3rem;
              font-weight: 700;
              line-height: 6.4rem;
              color: #fff;
              text-transform: uppercase;
              padding: 0 0 0px;
              max-width: 760px !important;
              margin: 0;

							@media ${device.laptopMedium} {
								font-size: 3.2rem;
								line-height: 3.8rem;
								padding-left: 0px;
								text-align: center;
								padding-left: 0 !important;
							}

							@media ${device.laptopPros} {
								padding-left: 0 !important;
							}

              @media ${device.ipadLandscapemin} {
                font-size: 4.2rem;
                line-height: 1.2 !important;
              }
              @media ${device.desktopsignup} {
                font-size: 5.3rem;
                line-height: 6.4rem;
              }

							p {
								width: 100%;
								strong {
									font-size: 5.3rem;
									font-weight: 700;
									line-height: 6.4rem;
									color: #fff;
									text-transform: uppercase;
									padding: 0 0 0px;
									width: 100%;

									@media ${device.laptopMedium} {
										font-size: 3.2rem;
										line-height: 3.8rem;
										padding-left: 0px;
										text-align: center;
									}
								}
							}
            }
          }
        }
      }
    }
    .resource-anchor-links-block {
      @media ${device.laptopPros} {
        margin-top: 60px;
      }
    }

		.logo-btm-space {
			margin: 120px 0 60px 0;
			position: relative;

      @media ${device.desktopsignup} {
        padding: 0 10rem;
      }
			@media ${device.laptopMedium} {
				padding-top: 0;
				margin-bottom: 6rem;
			}

			.btm-logo-section-wrapper {
				background-color: #f7f7f7;
				box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
				position: relative;
				padding: 10rem 10rem;
				color: #000000;
				text-align: center;

				&:before {
					height: 16px;
    			content: "";
					background: linear-gradient(90deg,#8000be 0%,#594dc6 35%,#17d0d4 64%,#00ffd9 100%);
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}

				@media ${device.laptopMedium} {
					padding: 2rem 0;
				}

				.o-header--h2 {
					padding-bottom: 24px;
					font-size: 3.8rem;

					@media ${device.laptopMedium} {
						font-size: 2.4rem;
    				line-height: 3.2rem;
					}
				}

				p {
					@media ${device.laptopMedium} {
						padding: 24px 0;
					}
					&:nth-child(1) {
						font-size: 2.6rem;
						line-height: 3.2rem;
						width: 100%;
					}
				}
			}

			.btm-logo-section-wrapper-container {
				padding: 0 0 42px 0;

				@media ${device.laptopMedium} {
					padding: 24px 15px
				}
			}
		}
    .logo-section {
      text-align: center;
      padding-top: 88px;
      margin-top: 20px;

      @media ${device.ipadLandscapemin} {
        padding: 0px 5rem;
      }

			&#get-connected {
				.o-container {

					@media ${device.ipadLandscape}{
						padding: 0 5rem;
					}
				}
				.o-image-wrapper-main {
					ul {
						li {
							height: 190px;
							line-height: 190px;


							@media ${device.laptop} {
								width: 245px !important;
							}
							@media ${device.laptopMedium} {
								padding: 20px 10px;
    						height: auto;
    						line-height: 0;
							}
							img {
								@media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
									width: auto;
								}
							}
						}
					}
				}
			}
			&.o-bottom-space {
				margin-top: 60px;
				padding-top: 0;

				@media ${device.laptop} {
					margin-top: 120px;
				}

				.o-container {

					@media ${device.ipadLandscape}{
						padding: 0 2rem;
					}
					@media ${device.laptopPros} {
						padding: 0 2.7rem;
					}
				}
			}
			&.logo-btm-space {
				@media ${device.laptopMedium} {
					padding-top: 0;
				}
			}
      .o-container {
        margin: 0 auto;
				max-width: 1208px;
				padding: 0 2rem;

				@media ${device.midDesktopMax} {
					padding: 0 2rem;
				}

				@media ${device.mobileMedium} {
					padding: 0 3rem;
				}

				@media ${device.laptopMedium} {
					max-width: 718px;
					font-size: 2rem;
					line-height: 2.6rem;
				}

				p {
					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.2rem;
					}
				}

        .o-inner {
          padding: 0;

          .o-other-texts {

						.logo-section-connect {
              @media ${device.laptopMedium}{
                font-size: 2.6rem;
                line-height: 3.2rem;
                margin: 40px auto;
              }
							@media ${device.desktopsignup} {
								font-size: 22px;
								font-weight: 500;
								line-height: 1.45;
								margin-bottom: 2rem;
							}
              @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax}{
                font-size: 2.6rem;
                line-height: 2.6rem;
                font-family: 'Gotham', Arial, Helvetica, sans-serif;
                font-weight: normal;
                margin: 40px auto;
                text-align: center;
                max-width: 768px;
              }
						}
            .thirdparty-desc {
              padding: 10px 0px 20px 0px;
              font-size: 16px;
              font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
              font-weight: 400;
              line-height: 2.4rem;
            }
          }

          .o-text-wrap {
						padding-bottom: 0;

						.ext-notice {
              @media ${device.laptopMedium}{
                font-size: 2rem;
                line-height: 2.6rem;
                font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
              }
							@media ${device.desktopsignup} {
								font-size: 18px;
								font-weight: 500;
								line-height: 1.33;
								margin-bottom: 0;
							}
              @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax}{
                font-size: 1.8rem;
                line-height: 2.6rem;
                font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
              }
						}

						&:nth-child(1) {
							@media ${device.desktopsignup} {
								display: none;
							}
						}
            .o-text-center {
              margin-bottom: 0;
              color: rgb(128, 0, 190);
            }
          }
        }
				.full-html-wrap {
					.get-connected {
						font-size: 2.6rem;
    				max-width: 768px;

						@media ${device.laptopMedium} {
							font-size: 2.6rem;
    					line-height: 3.2rem;
							padding-left: 0;
    					padding-right: 0;
						}
						@media ${device.laptopPros} {
							padding: 0 0;
						}
					}
					.get-connected-para {
						font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;

						@media ${device.laptopPros} {
							padding: 24px 20px;
						}
					}
				}
      }

			@media ${device.laptopMedium} {
				padding-top: 30px;
			}

			.o-header--h2 {
        font-size: 4.2rem;
        line-height: 5rem;
				font-weight: 600;
				max-width: 950px;
				text-align: center;
				color: #000000;
        margin-bottom: 3rem;

				@media ${device.laptopMedium} {
					font-size: 2rem;
          line-height: 2.6rem;
          font-weight: 700;
          padding-bottom: 35px 42px 32px;
				}
        @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
          padding-bottom: 88px 48px 40px;
				}
				@media ${device.desktopsignup} {
					font-weight: bold;
					max-width: 868px;
          margin: auto auto 3rem auto;
				}

				@media ${device.mobileMedium} {
					width: 99%;
				}

				@media ${device.ipadLandscapemin} and ${device.minideskend} {
					width: 99%;
				}

			}

			.o-block--center__small {
				max-width: 780px;
				margin: 0 auto;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: #000000;
				text-align: center;
				padding: 40px 0 24px 0;
				font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;

				@media ${device.laptopMedium} {
					font-size: 2rem;
					line-height: 2.6rem;
					width: 100%;
					padding: 24px 30px;
				}
			}

			.o-header--h4 {
				color: #8000BE;
				text-align: center;
				font-size: 2.7rem;
				font-weight: 600;
				line-height: 3.8rem;

				@media ${device.laptopMedium} {
					font-size: 2.7rem;
    			line-height: 2.8rem;
				}
			}

      .o-header {
        &.o-header--h3 {
          &.btm-logo-title {
            &.second-heading {
              padding: 0;
              margin: 50px 0 0;
							margin-bottom: 0;
							color: #8000be;
							font-size: 2.7rem;
							font-weight: 600;
							line-height: 3.8rem;

							@media ${device.desktopsignup} {
								margin: 3.5rem 0 0;
								font-weight: 700;
							}

								@media ${device.laptopMedium} {
									line-height: 2.4rem;
                  font-size: 2rem;
								}

              h3 {
                margin-bottom: 0;
                color: #8000be;
                font-size: 2.7rem;
                font-weight: 600;
                line-height: 3.8rem;
								margin-bottom: 0;

								@media ${device.laptopMedium} {
									line-height: 2.8rem;
								}
              }
            }
          }
        }
      }

			.logo-block {

				&.o-image-wrapper-main {
					display: grid;

					@media ${device.laptopair2max} {
						gap: 0;
					}
          @media ${device.desktopsignup} {
            padding: 0 5rem;
          }

					ul {
						width: 100% !important;

						li {

							@media ${device.laptop} {
								height: 187px;
    						line-height: 187px;
								width: 25% !important;
							}

							@media ${device.miniTopDeskMax} {
								height: 159px;
							}

							@media ${device.mobileMedium} {
								height: 122.188px;
							}


							.extlink {
								.media--image {
									@media ${device.ipadLandscapemin} {
										height: 136px;
  								}
                  @media ${device.laptop} {
                    height: 167px;
                  }
                  @media ${device.desktopsignup} and  ${device.midDesktopMax} {
                    height: 155px;
                  }

                  img {
                    height: auto;
                  }
								}

                &.o-button--primary {
                  &:after {
                    content: "";
                    display: inline-block;
                    width: 16px;
                    height: 14px;
                    margin-left: 10px;

                    @media ${device.laptopMedium} {
                      vertical-align: middle;
                      margin-top: -3px;
                    }
                  }
                }
							}

							&:nth-child(2) {
								.extlink {
									@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
										height: 100%;
										width: auto;
									}
									.media--image {
										media ${device.laptop} and ${device.minimumDeskEnd} {
											height: 100%;
										}
									}
								}
							}

							img {
								@media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
									width: 100%;
    							padding: 15px;
								}
								@media ${device.laptopMedium} {
    							width: 100%;
									margin: auto auto !important;
									max-height: 75px;
								}
							}
						}
					}
				}

				&.btm-logo-block {
					ul {

						@media ${device.midDesktopMax} {
							width: 100% !important;
						}
						li {
							@media ${device.miniTopDeskMax} {
								height: 158px;

								img {
									width: 100%;
								}
							}

							@media ${device.mobileMedium} {
								height: 121.188px;
							}
						}
					}
				}
				&.o-image-wrapper-main,
				&.btm-logo-block {
					margin: 5rem 0 0;
					width: 100%;

					@media ${device.miniTopDeskMax} {
						margin: 0;
						padding-top: 24px;
						margin-bottom: 24px;
					}

          ul {
            display: inline-flex;
            margin: 0;
            padding: 0;
            width: auto;
            justify-content: center;

            li {
              display: block;
              line-height: 190px;
              width: 245px;
              text-align: center;
              border-bottom: 1px solid #707070;
              align-items: unset;
              justify-content: unset;
              float: none;
							height: 187px;
							line-height: 187px;
							padding: 0 0;
    					align-items: center;

							@media ${device.laptop} {
								height: 187px;
    						line-height: 187px;
							}
							@media ${device.laptopPros} {
								padding: 15px 15px;
								height: 100px;
								line-height: 25px;
							}

							&:last-child {
								border-right: 0!important;
							}

							@media ${device.miniTopDeskMax} {
								display: flex;
								justify-content: center;
								width: 50% !important;
								line-height: 0;
							}

							a {
								cursor: pointer;

								img {
									vertical-align: middle;
									text-align: center;
									margin: auto;
									max-width: 100%;

									@media ${device.desktopsignup} and ${device.midDesktopMax} {
										vertical-align: bottom;
									}
									@media ${device.miniTopDeskMax} {
										margin: auto auto;
									}

									@media ${device.minimumDesk} {
										max-width: 100%;
									}
								}
							}
						}

						&:last-child {
							li {
								border-bottom: 0;
								@media ${device.desktopsignup} {
									height: auto;
								}
							}
						}
					}
				}
			}
		}

		.o-top-space {
			h2 {
				margin-bottom: 0;
			}
		}
		.video-thumbnail-section {
			h2 {
				padding: 0 0 40px 0;
				margin-bottom: 0;

				@media ${device.laptopMedium} {
					top: 5%;
    			padding: 0 0 24px 0;
				}
			}

			.o-internal--h2 {
        font-size: 4.2rem;
        line-height: 5rem;
				font-weight: bold;
				color: #000000;
				text-align: center;
				font-family: "Gotham", Arial, Helvetica, sans-serif;

				@media ${device.laptopMedium} {
					text-align: center;
					font-size: 2.5rem;
					line-height: 3rem;
				}
        @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
          margin: 60px 0 0;
          padding: 0 0 40px 0;
        }
				@media ${device.desktopsignup} {
					margin: 58px 0 0;
				}
			}

			.o-block--center__small {
				font-size: 2.6rem;
				max-width: 1188px;
				margin-left: auto;
				margin-right: auto;
			}

			.o-paragraph--center {
				width: 80%;
				margin: 0 auto;
				margin-bottom: 40px;
				margin-top: 60px;
        max-width: 768px;

				&:nth-child(2) {
					margin-top: 0;

					@media ${device.desktopsignup} and ${device.largeMaxDesktop} {
  					max-width: 768px;
						font-weight: normal;
					}
				}
			}

			.hear-from-retinal {
				width: 100%;
				font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
				text-align: center;
				max-width: 768px;
				font-size: 2.2rem;
				line-height: 3.2rem;
				color: #000;
				font-weight: normal;
				margin-bottom: 20px;

				@media ${device.laptopMedium} {
					font-size: 1.8rem;
					line-height: 2.2rem;
				}

				~p {
					max-width: 668px;
					font-size: 1.8rem;
					color: #000;
					line-height: 2.8rem;
					font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;s
					text-align: center;

					@media ${device.laptopMedium} {
						font-size: 1.6rem;
						line-height: 2.2rem;
					}
				}
			}


			.o-paragraph {
				&.hear-from {
					font-weight: 700;

					@media ${device.laptopPros} {
						margin-top: 4rem !important;
						padding-left: 0;
						padding-right: 0;
						line-height: 32px;
						font-size: 2.6rem;
						font-family: 'Gotham', Arial, Helvetica, sans-serif;
						font-weight: 600;
    				width: 98%;
					}
					@media ${device.laptopMedium} {
						margin-top: 4rem;
					}

					a{
						color: #8000BE;
					}
				}

				&.o-block--center__small {
					font-size: 2.2rem;

					@media ${device.laptopPros} {
						margin-top: 0;
						font-size: 1.8rem;
						padding-left: 0;
						padding-right: 0;
					}
					@media ${device.laptopMedium} {
						font-size: 1.8rem;
					}
				}
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: #000000;

				@media ${device.laptopMedium} {
					font-size: 1.8rem;
					line-height: 2.6rem;
					width: 100%;
					padding: 0 1rem;
				}
			}

		}

		.o-column--full {
			width: 100%;


		}

		.video-container {
			&.o-container {
				@media ${device.desktopsignup} {
					max-width: 1208px;
          padding: 0px 2rem;
				}
			}
			.o-inner {
				padding: 0 0;

        @media ${device.ipadLandscapemin} {
          padding-left: 100px;
          padding-right: 100px;
        }
				.video-thumbnail-section-main {
					display: flex;
					padding: 0 0 0px;
					padding-bottom: 70px;
    			position: relative;
					align-items: flex-start;

          @media ${device.miniTopDeskMax} {
            padding-bottom: 65px;
          }
					.video-transcript-copy {
						box-shadow: rgb(0 0 0 / 20%) 0px 5px 15px 0px;
						background-color: rgb(247, 247, 247);
						height: 50vh;

						@media ${device.laptopair2max} {
							height: 50vh !important;
							max-width: 100%;
						}
					}

          .video-thumbnail-left {
            @media ${device.ipadLandscapemin} {
              width: 38% !important;
            }
          }
          .video-thumbnail-right {
            @media ${device.ipadLandscapemin} {
              width: 62% !important;
              padding-left: 32px !important;
            }
          }


					.o-button {
						padding-top: 10px;
						padding-bottom: 10px;
						line-height: 3.2rem;
						padding: 10px 25px;
						padding-right: 23px;

						@media ${device.ipadLandscapemin} {
							box-shadow: rgb(0 0 0 / 30%) 0px 5px 15px 0px;
						}
						@media ${device.desktopsignup} {
							line-height: 1.09;
						}

						@media ${device.laptopMedium} {
							order: 2;
							padding-top: 10px;
							padding-bottom: 10px;
							padding-left: 0;
						}

						.dtp-arrow-down {
							margin-left: 3px;
						}
					}

					@media ${device.laptopMedium} {
						padding-bottom: 0;
						width: 100%;
					}

					.video-thumbnail-left {

						.mx-auto {
							max-width: unset !important;
						}

						@media ${device.laptopMedium} {
							width: 100%;
						}
					}

					.video-thumbnail-right {
						padding-left: 32px;
						@media ${device.ipadLandscape} {
							width: 61%;
							margin-left: 20px;
							padding-left: 0;
						}

						.o-common-title {
							p {
								@media ${device.desktopsignup} {
									width: 94%;
									margin-left: 0;
								}
								b {
									@media ${device.desktopsignup} {
										font-weight: bold;
									}
                  @media ${device.ipadLandscapemin} and ${device.minideskend}{
                    font-weight: 500;
                  }
								}
							}
						}

						.o-paragraph {

							p{
								color: #000000;
								width: 83%;
								font-size: 1.8rem;
								line-height: 2.6rem;
								font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
								margin-left: 0;
                padding-top: 16px;

								@media ${device.laptopMedium} {
									width: 100%;
									text-align: center;
									padding-bottom: 0;
                  font-size: 1.8rem !important;
                  line-height: 2.6rem !important;
                  margin-bottom: 21px;

								}
								@media ${device.desktopsignup} {
									padding-top: 2.4rem;
									font-size: 22px;
									line-height: 1.45;
									width: 100%;
								}
							}
						}

						@media ${device.laptopMedium} {
							width: 100%;
							padding: 0;
							margin-left: 0;
							margin-top: 20px;
							order: 4;
						}

						@media ${device.laptopMedium} {
							order: 4;
						}


						.o-header--h3 {
							&:nth-child(2) {
								@media ${device.desktopsignup} {
									width: 85%;
								}
							}

							h3 {
								@media ${device.laptopMedium} {
									width: 100%;
									max-width: 100%;
									line-height: 2.6rem !important;
								}

								@media ${device.laptop} {
									font-weight: 700;
								}
							}
						}
					}
				}
			}
		}

    .supporting-your-patients-case-studies {
      margin: 0 0;
      position: relative;

			@media ${device.laptopMedium} {
				margin: 40px 0 48px;
			}

			@media ${device.laptopPros} {
				margin: 40px 0 20px;
			}

			&:before {
				content: "";
				background: #00FFD9;
				display: block;
				width: 100%;
				height: 70%;
				position: absolute;
				top: 15%;

				@media ${device.laptopMedium} {
					top: 5%;
					height: 92%;
				}
			}

			.o-container {

				.c-family--story__inner {
					background-color: #f7f7f7;
					box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
					position: relative;
					padding: 10rem 9rem;
					color: #000000;
					text-align: center;

					@media ${device.laptopMedium} {
						padding: 2rem 3rem;
						font-size: 2rem;
    				line-height: 2.6rem;
					}


					&:before {
						height: 16px;
						content: "";
						background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
						width: 100%;
						position: absolute;
						top: 0;
						left: 0;
					}

					.o-header--h2 {
						font-size: 4.2rem;
						font-weight: 600;
						line-height: 5rem;
						color: #000000;
						margin: 0 0 40px;

						@media ${device.laptopMedium} {
							text-align: center;
							margin: 30px 0 24px;
							font-size: 2.5rem;
    					line-height: 3rem;
						}

					}

					.o-paragraph {
						font-size: 1.8rem;
						line-height: 2.6rem;
						width: 80%;
						margin: 0 auto 44px;
						text-align: center;
						font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;

						@media ${device.laptopMedium} {
							margin: 0 auto 24px;
							width: 100%;
						}
						@media ${device.desktopsignup} {
							font-weight: normal;
              width: 70%;
						}
					}

					.o-columns--row {
						display: flex;
						flex-wrap: wrap;
						flex-direction: row;
						justify-content: space-between;
						align-items: stretch;
						margin: 30px 0 0;

						@media ${device.laptopMedium} {
							margin: 0;
							justify-content: stretch;
							display: block;
						}

						.o-columns--one {
							width: 49% !important;
							margin: auto;

							@media ${device.laptopMedium} {
								width: 100% !important;
								padding: 15px 20px !important;
								margin: 0 0 20px;
							}
							@media ${device.desktopsignup} {
								padding: 30px 24px 100px;
							}
						}

						.o-columns--two,
						.o-columns--one {
							padding: 30px 25px 100px;
							box-sizing: border-box;
							position: relative;
							text-align: center;
							width: 48.5%;
							background: #fff;

							@media ${device.laptopMedium} {
								padding: 25px;
								margin: 0;
								margin-bottom: 16px;
								width: 100%;
							}

							.o-header--h3 {
								color: #8000BE;
								font-size: 2.7rem;
								line-height: 3.8rem;
								font-weight: 600;
								margin-bottom: 0;

								@media ${device.laptopMedium} {
									font-size: 1.8rem;
    							line-height: 2.6rem;
									margin-bottom: 0;
								}
							}

							.o-paragraph {
								font-size: 1.8rem;
    						line-height: 2.6rem;
								margin: 30px auto;
    						width: 100%;
								font-weight: 500;
								font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
								color: #000000;

								@media ${device.laptopMedium} {
									margin: 16px 0 32px 0;
									font-size: 1.8rem;
									line-height: 2rem;
								}
								@media ${device.desktopsignup} {
									font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
								}

								&:last-child() {
									@media ${device.laptopMedium} {
										margin: 10px 0px 20px 0;
									}
								}
							}

							.o-button {
								box-shadow: 0 3px 4px #d6d6d6;
								position: absolute;
								bottom: 40px;
								left: 0;
								right: 0;
								margin: 0 auto;
								display: inline-block;
								max-width: 252px;
								padding: 10px;
								background: #8000BE;
								color: #fff;
								border: 1px solid #fff;
								font-size: 2.2rem;
								font-weight: 500;
								line-height: 3.2rem;
								text-decoration: none;
								z-index: 1;
								vertical-align: top;
								overflow: hidden;
								letter-spacing: 0;
    						border-radius: 6px;
								transition: all 0.3s ease;
    						cursor: pointer;

								@media ${device.laptopMedium} {
									position: unset;
									font-size: 1.8rem;
									line-height: 2.6rem;
								}
								@media ${device.desktopsignup} {
									max-width: 296px;
									padding-top: 13px;
									padding-bottom: 15px;
								}


								&:hover {
									background: #fff;
									color: #8000BE;
									border-color: #fff;
									font-weight: 700;
									text-decoration: none;
								}
							}
						}
					}
				}
			}
		}

		.supporting-your-patients-clinical-trail {
			@media ${device.tablet} and ${device.laptopMedium} {
				padding: 0 5rem;
			}
			@media ${device.desktopsignup} and ${device.miniDesktop} {
				max-width: 1060px;
				padding: 0 2rem;
				margin-left: auto;
				margin-right: auto;
			}

			.text-bg-box__space {
				padding: 88px 90px 50px;
				margin: 100px 0 0;
				background-color: #8000BE;

				@media ${device.laptopMedium} {
					margin: 0;
					padding: 48px 30px 28px;
				}
				@media ${device.desktopsignup} {
					margin-top: 5rem;
				}

				.o-internal--h2 {
					font-size: 4.2rem;
					font-weight: 700;
					line-height: 5rem;
					color: #fff;
					text-align: center;

					@media ${device.laptopMedium} {
						line-height: 2.6rem;
    				margin-bottom: 10px;
						text-align: center;
						font-size: 2.5rem;
					}
				}

				.o-paragraph {
					width: 80%;
					margin: auto;
    			text-align: center;
					padding: 40px 0;
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;
					color: #fff;

					@media ${device.laptopMedium} {
						font-size: 2rem;
						line-height: 2.6rem;
						padding-bottom: 20px;
						padding-top: 20px;
						width: 100%;
					}
					@media ${device.desktopsignup} {
						width: 100%;
					}

					&.seek-para-style1 {
						@media ${device.desktopsignup} {
							padding-bottom: 3.2rem;
						}
					}
					&.seek-para-style2 {
						@media ${device.desktopsignup} {
							padding-top: 0;
							padding-bottom: 3rem;
						}
					}
					&.seek-para-style3 {
						@media ${device.desktopsignup} {
							padding-top: 0;
							padding-bottom: 12rem;
						}
					}


					sup {
						font-size: 60%;
						top: -0.5em;
						position: relative;
					}

					a {
						display: block;
						text-decoration: underline;
						color: #fff !important;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;

						@media ${device.laptopMedium} {
							font-size: 2rem;
						}

						@media ${device.desktopsignup} {
							display: inline-block;
						}

						&:after {
							position: relative;
							top: 1px;
						}

						&:hover {
							text-decoration: none;
						}

						span {
							color: #8000BE;
							display: none;

							.link-img {
								position: initial;
								padding: 0 5px;
								margin-top: -10px;
							}
						}
					}
				}
			}
		}

		.patients-questions-wrapper {
			h2 {
				font-size: 4.2rem;
				font-weight: 700;
				line-height: 5rem;
				color: #000000;
				text-align: center;
				padding: 0 0 40px 0;

				@media ${device.laptopMedium} {
					top: 5%;
    			padding: 0 0 24px 0;
					text-align: center;
					font-size: 2.5rem;
					line-height: 3rem;
				}
			}

			.o-paragraph--center {
				max-width: 780px;
				margin: 0 auto 40px;
				width: 80%;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: #000000;
				text-align: center;
				font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

				@media ${device.laptopMedium} {
					font-size: 1.8rem;
					line-height: 2.6rem;
					width: 100%;
				}
			}
		}
	}
`;

export const HcpSYPmainwrapper = styled.div`
  &.hcpsupportingpatientwrapper{
    #patient-support{
      height:0;
    }
    .hcpsupportpatience{
      &:not(.patient-genetic){
        .show{
        +.main {
            padding-top: 0px;
          }
        }
      }
      .supportparentswrapper{
        .logo-section{
          .logo-block{
            .o-image-wrapper-main{
              ul{
                @media ${device.ipadLandscapemin}{
                  width: 50% !important;
                }
              }
          }
        }
      }
    }
  }

  .footer{
    margin:0;
    .o-backtotop-btn{
      top: 30px;
      background-color: transparent;
      @media ${device.ipadLandscapemin}{
        top: -135px;
        background: #300064;
      }
    }
    .footercontainer{
      margin: 0 auto;
      .row{
         padding: 8rem 0 0;

        @media ${device.ipadLandscapemin} and  ${device.desktopmenu}{
          padding: 6rem 0 0;
        }
        .left-footer{
          .row{
                padding: 0;
          }
        }
      }
    }
  }
`;

export const SupportingYourParentsModalWrapper = styled.div`
	width: 100%;

	.modal-body {
		padding: 0;
    text-align: left;
		position: relative;
		flex: 1 1 auto;

    .o-header--h3{
      font-family: 'Gotham', Arial, Helvetica, sans-serif;
      font-weight: 600;
    }

    .retina-modal-body-container{
      p{
        font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
      }
    }
		.button-close {
			color: #fff;

			@media ${device.ipadLandscape} {
				background-size: 25px;
				background-repeat: no-repeat;
				right: -10px !important;
				text-align: right;
				float: right;
			}
		}

		.modal-header {
			background-color: #300064;
			padding: 48px 48px;
			display: inline-block;
			text-align: left;
			position: relative;
			border: 0;
			width: 100%;
			align-items: flex-start;
			justify-content: space-between;
			border-top-left-radius: 0.3rem;
    	border-top-right-radius: 0.3rem;

			@media ${device.laptopMedium} {
				padding: 30px;
			}

			.o-header {
				color: #00FFD9;
				font-size: 3.2rem;
				font-weight: 700;
				line-height: 3.8rem;
				max-width: 707px;

				@media ${device.laptopMedium} {
					font-size: 2.2rem;
    			line-height: 2.6rem;
				}
			}

			.o-paragraph {
				color: #fff;
				font-weight: 700;
				padding-top: 8px;
				max-width: 822px;
				font-size: 2.2rem;
				line-height: 3.2rem;

				@media ${device.laptopMedium} {
					font-size: 1.8rem;
					line-height: 2.6rem;
					padding: 20px 0 0;
				}
			}

			&:after {
				content: "";
				background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
				position: absolute;
				bottom: -18px;
				width: 100%;
				height: 18px;
				left: 0;
			}
		}

		.modal-inner-content {
			padding: 48px;
			background-color: #fff;
			width: 100%;

			@media ${device.laptopMedium} {
				padding: 30px;
				padding-top: 42px;
			}

			.modal-title {
				color: #000000;
				font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
				margin-bottom: 36px;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;

				@media ${device.laptopMedium} {
					margin-bottom: 27px;
					font-size: 1.8rem;
    			line-height: 2.6rem;
				}

				&.list-title {
					margin-bottom: 8px;
				}

				span {
					color: #300064;
					font-family: "Gotham", Arial, Helvetica, sans-serif;
					font-weight: 400;
				}
			}

			.modal-points {
				width: 100%;

				@media ${device.laptopMedium} {
					padding-left: 3px;
				}

				ul {
					padding-left: 5px;
					margin-bottom: 36px;
					list-style-type: none;

					@media ${device.laptopMedium} {
						padding-left: 0px;
						margin-bottom: 22px;
					}

					li {
						position: relative;
						color: #000000;
						font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
						padding: 0 0 0 25px;
						margin-bottom: 5px;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
    					line-height: 2.6rem;
						}

						span {
							font-family: 'Gotham', Arial, Helvetica, sans-serif;
							color: #000000;
						}

						&:after {
							content: "";
							position: absolute;
							width: 8px;
							height: 8px;
							background-color: #8000BE;
							top: 13px;
							left: 0;

							@media ${device.laptopMedium} {
								top: 9px;
							}
						}
					}
				}
			}

			.text-center {
				text-align: center;

				.back-eye {
					width: 100%;
					max-width: 568px;
					padding-bottom: 36px;

					@media ${device.laptopMedium} {
						margin-bottom: 22px;
						padding: 0;
					}
				}

				img {
					width: 100%;
					padding-bottom: 10px;

					@media ${device.laptopMedium} {
						padding-bottom: 10px;
					}
				}
			}

			.key-takeway-section {
				border: 4px solid #300064;
				width: 100%;

				.modal-points {
					padding: 40px 40px 0;

					@media ${device.laptopMedium} {
						padding: 15px 12px 0;
					}

					p {
						color: #300064;
						padding-bottom: 10px;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
    					line-height: 2.6rem;
						}

						span {
							color: #000000;
							font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
						}
					}
				}

				.key-takeway-paragraph {
					background-color: #300064;
    			padding: 36px 56px;
					width: 100%;
					margin-top: 38px;

					@media ${device.laptopMedium} {
						margin-top: 10px;
						padding: 20px;
					}

					h3 {
						color: #00FFD9 !important;
						text-align: left;
						font-size: 2.7rem;
						line-height: 3.8rem;
						margin: auto !important;
						width: 100% !important;
						font-weight: 600;

						@media ${device.laptopMedium} {
							font-size: 2.2rem;
    					line-height: 3.2rem;
						}
					}

					p {
						color: #fff;
						text-align: left;
						padding-top: 16px;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
    					line-height: 2.6rem;
						}

						&.o-paragraph {
							margin-bottom: 0 !important;
						}

						~p {
							display: none;
						}
					}
				}
			}

			.modal-ref-text {
				text-align: left;
				font-size: 1.6rem;
				margin-top: 24px;
			}

			.modal-small-paragraph {
				color: #000000;
				font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
				font-size: 1.6rem;
				line-height: 1.8rem;
				text-align: left;
				width: 100%;
			}

			.modal-body-small-text {
				text-align: left;
				font-size: 2rem;
				padding: 32px 0;
				color: #000000;
				line-height: 2.6rem;
				font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
				width: 100%;

				@media ${device.laptopMedium} {
					padding: 20px 30px;
					text-align: center;
				}
			}

			.modal-image-container {
				width: 100%;
				display: flex;
				justify-content: center;

				.desktop-image {
					@media ${device.laptopPros} {
						display: none;
					}
				}

				.mobile-image {
					@media ${device.tablet} {
						display: none;
					}
				}

				img {
					width: 100%;
					padding-bottom: 10px;

					@media ${device.laptopMedium} {
						padding-bottom: 10px;
					}
				}

				&.eye-diagram {
					img {
						width: 568px;
						padding-bottom: 10px;

						@media ${device.laptopMedium} {
							padding-bottom: 10px;
							width: 100%;
						}
					}
				}
			}
		}
	}
`;
