export const hcpSYP: any = {
    hcpHomeMainMenu: "Main Navigational Menu HCP GCSO - Retina Spain",
    hcpHTMLBanner: "Banner - HTML - RC - HCP - Retina Spain",
    hcpFooter: "Footer HCP - Retina Spain",
    hcpExitPopup: "Exit Popup - HCP - Retina Spain",
    hcpHCPSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
    hcpTopNav: "Top Navigational Menu HCP - Retina Spain",
    hcpSiteLogo: "Site logos - Retina Spain",
    hcpFootRef: "Reference - RC - HCP - Retina Spain",
    hcpRealWorldPara: "Real World Case Studies - RC - HCP - Retina Spain",
    hcpClinicalTrialPara: "Seeking a Clinical Trial - RC - HCP - Retina Spain",
    hcpLogoGarden: "logo garden - RC - HCP - Retina Spain",
    video2Section: "Video Heading - RC - HCP - Retina Spain",
    video2: "Video Section - RC - HCP - Retina Spain",
    hcpRCSModal1: "Revealing Modal - RC - HCP - Retina Spain",
    hcpRCSModal2: "Overlapping Modal - RC - HCP - Retina Spain",
    hcpRCSModal3: "Denovo Modal - RC - HCP - Retina Spain",
    hcpRCSModal4: "Retesting Modal - RC - HCP - Retina Spain",
    hcpRCSModal5: "Benefit Modal - RC - HCP - Retina Spain",
    backToTopGTM: "back to top - supporting your patients - hcp",
    footerClassName: "supporting-your-patients-footer",
    video23Attrs: {
      viewTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "genes 101 - view transcript",
          "className": "gtm-accordion",
          "aria-label": "Ver la transcripción para Genes 101"
        },
        {
          "data-gtm-event-label": "inheritance 101 - view transcript",
          "className": "gtm-accordion",
          "aria-label": "Ver la transcripción para Herencia 101"
        }
      ],
      hideTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "genes 101 - hide transcript",
          "className": "gtm-accordion",
          "aria-label": "Ver la transcripción para Genes 101"
        },
        {
          "data-gtm-event-label": "inheritance 101 - hide transcript",
          "className": "gtm-accordion",
          "aria-label": "Ver la transcripción para Herencia 101"
        }
      ]
    },

  }
